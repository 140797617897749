<template>
    <div>
      <br /><br />
      <img :src="`${publicPath}assets/blogs/jobsite-manager.webp`" alt="Record TIME for your plumbing business" style="width:100%" />
  
      <br />
      <br />
      <h1>Jobsite Manager: Enhanced Efficiency and Transparency in Jobsite</h1> <br />
     

      <p>Technological breakthroughs continue to transform how jobs are planned, completed, and managed in the ever-changing business world. If you are an investor or a business owner, embracing new technology is always beneficial. With the advancement of technology, Record TIME jobsite manager has made site manager jobs easier and has emerged as a game-changer, giving a complete solution. It also assists businesses in maximising job site staff productivity. Let's look at the features that make Jobsite Manager vital for modern projects.

        </p>
     
      
      <div>
      <div class="container landing-banner-container" style="padding-top: 10px">

<div>
 <div>
                  
                  
                    <ul><li>    <h3>           
                    QR Code Sign-In: Simplifying Attendance Management</h3></li>

                    <p>One of Jobsite Manager's notable features is its creative usage of QR codes for smooth check-ins. Field personnel can easily check in to the job site by scanning the QR code, eliminating the need for manual documentation and lowering the possibility of communication gaps. The quick and easy check-ins not only save time but also help to improve attendance monitoring throughout the project's lifecycle and reduce paperwork.</p>
                    


                  
                   <li>               <h3> 
                    Geofencing for Precise Location Tracking
</h3></li>

                    <p>Jobsite Manager contains innovative geofencing, which allows customers to establish virtual boundaries around job locations. All activities within permitted areas are accurately tracked. The manager oversees this functionality by restricting check-ins and check-outs outside of a predefined radius, ensuring that the staff log their hours when physically present at the workplace, thus enhancing the accuracy and reliability of the site manager role.

                    </p>
                    


                  
                   <li>    <h3>            
                    Real-time Notifications for Enhanced Visibility
</h3></li>

                    <p>The jobsite manager's geofencing tool does more than just set virtual limits. It also lets project managers know what's happening in real-time. It sends automatic messages when someone enters or leaves the virtual fences. This gives the managers real-time information on attendance and activities. This strategy helps improve project management by allowing quick responses to changes on-site for site agent.

                    </p>

                    <li>               <h3> 
                        Tracking Activities for Comprehensive Insights
</h3></li>
    
                        <p>Jobsite Manager goes beyond basic by tracking various events such as user accessibility, geofence breaches, check-ins, and check-outs. The system categorises activities such as "Unreachable" when a user's device is turned off and also when the app is closed or "Out of Fence" when a mobile device reaches outside of the geofence location. The level of information assures that the project manager has a complete view of the project's progress.

                            
                        </p>
                    
                     <li>   <h3>             
                       Status Monitoring and Data Generation </h3>
                    </li>

                    <p>Record TIME jobsite manager <a href="https://recordtime.com.au/field-staff-real-time-tracking"> monitors staff status </a> for real-time visibility into on-site presence, increasing accountability, problem solving, and facilitating quick decision-making. This feature encourages proactive management by delivering up-to-date information to project leaders to improve workflows, review progress, and ensure success.



                    


                   </p>

              </ul>
              </div><br />  
  <h2>Benefits of Jobsite Manager
</h2>   
  <div class="left-banner">
                             <div>
                 
                  
                 <p>    <br>            
                 
                    <ul><li>    <p>           
                    <b>Attendance Accuracy:</b> The site manager gives data about employees and workers going in and out of job sites. This makes sure the attendance records are correct.
</p></li>
                    </ul>
                    <ul><li>    <p>     
                   <b>Paperless operation:</b> The software stores all of the user's activities. This creates a paperless environment for effective record-keeping.
</p></li>
                    </ul>
                    <ul><li>    <p>           
                    <b>Precision Time Logging:</b> The program additionally enables accurate logging of entry and exit timings, making it easier to measure the total hours and dates worked on the job. Furthermore, this information can be extremely useful for payroll and project management.
</p></li>
                    </ul>
                    <ul><li>    <p>           
                    <b>Location-specific:</b> Geofencing zones ensure that employees can only clock in or out when they are within a specific radius of their job. It eliminates incorrect time and location reporting.
</p></li>
                    </ul>
                            
                   
                    </p>
                </div>
            </div>
            <div  class="right-banner" style="text-align: right;">
            <center>  <img :src="`${publicPath}assets/blogs/benefits-jobsite-manager.webp`" alt="Tracking activities on-site using job site manager" style="width: 80%; height: 391px; object-fit: contain;" /></center>
            </div>
            <div style="clear: both;"></div>
            <p>
              Jobsite Manager transforms site management by incorporating current technologies and automation. Additionally, this feature assures the management of your site and team members. It provides a platform for QR code sign-ins, geofencing, and activity tracking.
</p>
</div>                     
            
            
</div>


           
<div>

            










            

        </div>


       

        


        <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
          @click="changeParam('jobsite-management-software')" class="mr-7 btn-1" elevation="0">
          Read More
      </v-btn>


      </div>
    </div>








    
</template>
<style scoped lang="scss">

.landing-banner-container{
      @media only screen and (max-width: 600px) {
          padding-top: 20px !important;
      }
  }
  .left-banner{
      width: 45%; 
      float: left;
      @media only screen and (max-width: 1280px) {
          width: 100%;
      }
  }
  .right-banner{
      width: 55%; 
      float: right;
      padding: 0px 20px 20px;
      @media only screen and (max-width: 1280px) {
          width: 100%;
      }
  }

  .gray-container{
      background: #F8F9FB;
      padding: 20px;
      margin-top: 30px;
  }

.card-list {
  background: #f5f5f5;
  padding: 20px 0px;
  margin-bottom: 20px;
  .title {
    text-align: center;
    font-size: 26px !important;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .list-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 2fr));
    grid-template-rows: repeat(2, minmax(150px, 2fr));
    @media only screen and (max-width: 600px) {
      grid-template-columns: repeat(1, minmax(150px, 2fr));
      grid-template-rows: repeat(1, minmax(150px, 2fr));
    }
    > div {
      //   flex: 1 1;
      background: white;
      margin: 20px;
      text-align: center;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
      }
      h3 {
        margin-top: 20px;
      }
      p {
        text-align: center;
        padding: 20px;
      }
    }
  }
}
</style>
<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    changeParam(param){
      window.location = window.location.origin + "/"+ param
    }
  }
};
</script>
